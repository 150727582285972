export const objectToQueryString = (initialObj: any) => {
    const reducer = (obj: any, parentPrefix = null) => (prev: any, key: any) => {
        const val = obj[key];
        key = encodeURIComponent(key);
        const prefix = parentPrefix ? `${parentPrefix}[${key}]` : key;

        if (val == null || typeof val === 'function') {
            prev.push(`${prefix}=`);
            return prev;
        }

        if (['number', 'boolean', 'string'].includes(typeof val)) {
            prev.push(`${prefix}=${encodeURIComponent(val)}`);
            return prev;
        }

        prev.push(Object.keys(val).reduce(reducer(val, prefix), []).join('&'));
        return prev;
    };

    return Object.keys(initialObj).reduce(reducer(initialObj), []).join('&');
}

export const buildFormData = (formData: any, data: any, parentKey?: any) => {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;
    
        formData.set(parentKey, value);
    }
}
      
export const jsonToFormData = (data: any, formData?: FormData) => {
    if(!formData) {
        formData = new FormData();
    }
    buildFormData(formData, data);
    return formData;
}

export const saveStateToStorage = (userId: number | undefined, data: any, formData: any) => {
    // console.log(data, formData);
    window.localStorage.setItem('MY_APP_STATE_DATA_' + (userId ?? ''), JSON.stringify(data));
    // window.localStorage.setItem('MY_APP_STATE_FORM_DATA_' + (userId ?? ''), JSON.stringify(Object.fromEntries(formData)));
}

export const restoreStateFromStorage = (userId: number | undefined) => {
    const myStateData = window.localStorage.getItem('MY_APP_STATE_DATA_' + (userId ?? '')) || '{}';
    // const myStateFormData = window.localStorage.getItem('MY_APP_STATE_FORM_DATA_' + (userId ?? '')) || '{}';
    return JSON.parse(myStateData);
    // return [
    //     JSON.parse(myStateData),
    //     JSON.parse(myStateFormData),
    // ];
}