const projectTypes = [
    {
        "name": "Commercial",
        "code": "COMMERCIAL"
    },
    {
        "name": "Residential",
        "code": "RESIDENTIAL"
    },
    {
        "name": "Mixed",
        "code": "MIXED"
    },
    {
        "name": "Industrial",
        "code": "INDUSTRIAL"
    },
    {
        "name": "Linear",
        "code": "LINEAR"
    },
    {
        "name": "Roadway",
        "code": "ROADWAY"
    },
    {
        "name": "Other",
        "code": "OTHER"
    }
]

export default projectTypes;