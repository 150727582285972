// For server

console.log('process.env.NODE_ENV', process.env.NODE_ENV);
// export const BASE_URL = "https://siltpros-backend.kodmonk.com";
// export const BASE_URL = "https://silttracker-backend.kodmonk.com";
export const BASE_URL = "https://backend.silttracker.com";
// export const BASE_URL = "http://192.168.29.51";
// export const BASE_URL = "http://localhost";

export const API_URL = BASE_URL + "/api";
export const STORAGE_URL = BASE_URL + "/storage";