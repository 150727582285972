import {
    IonAlert,
    IonBreadcrumb,
    IonBreadcrumbs,
    IonCard,
    IonCardContent,
    IonCheckbox,
    IonCol,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonLabel,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonToast,
    IonToolbar,
    useIonLoading,
    useIonViewDidEnter
} from '@ionic/react';
import { arrowBack } from "ionicons/icons";
import {
    IonContent,
    IonPage,
    IonButton,
    IonButtons
} from '@ionic/react';
import './index.css';
import { Link, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { API_URL } from '../../config';
import { authFetch } from '../../auth-provider';
import states from '../../config/states';
import areaUnits from '../../config/area-units';
import { logout } from '../../auth-provider';
import * as utils from '../../services/utils';
import inspectionTypes from '../../config/inspection-types';
import options from '../../config/options';
import weatherTypes from '../../config/weather-types';
import inspectionItemTypes from '../../config/inspection-item-types';

const MaintenanceRequest: React.FC = () => {
    const history = useHistory();

    const [showAlert, setShowAlert] = useState(false);
    // eslint-disable-next-line
    const [inspectors, setInspectors] = useState([]);
    const [jobs, setJobs] = useState([]);

    const [data, setData] = useState<any>();
    const [formData, setFormData] = useState<any>(new FormData());

    const [iserror, setIserror] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState<string>("");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [present, dismiss] = useIonLoading();

    useIonViewDidEnter(() => {
        fetchJobs();
    })

    const onFormChange = (key:string, value: any) => {
        // console.log('[onFormChange]');
        // console.log('key', key);
        // console.log('value', value);
        // console.log('data', data);
        const updatedData = {
            ...data,
            ...{
                [key]: value
            }
        };

        setData(updatedData)

        setFormData(utils.jsonToFormData(updatedData));
    };

    const onFileChange = (key:string, e: any) => {
        // // Get a reference to the file that has just been added to the input
        // const photo = e.target.files[0];
        // // Create a form data object using the FormData API
        // let data = new FormData();
        // // Add the file that was just added to the form data
        // data.append('photo', photo, photo.name);
        // // POST data to server using Fetch API
        const updatedData = {
            ...data,
            ...{
                [key]: e.target.files[0]
            }
        };
        setData(updatedData);

        setFormData(utils.jsonToFormData(updatedData));
    };
    const onSubmit = (e: any) => {
        e.preventDefault();

        setIserror(false);
        setMessage("");
        setIsToastOpen(false);
        setToastMessage("");

        present({
            message: 'Submitting...',
        });

        authFetch(API_URL + "/maintenance-requests", {
            method: 'POST',
            body: formData
        })
            .then(r => {
                if(r.ok === false && r.status === 401) {
                    logout();
                    history.push('/login');
                    return;
                }
                return r.json()
            })
            .then(response => {
                dismiss();
                const message = response?.message;
                const data = response?.data;
                if(data) {
                    setIsToastOpen(true);
                    setToastMessage("Maintenance request sent successfully.");
                    history.push("/dashboard");
                    return;
                }
                setIserror(true);
                setMessage(message);
                console.log('response', response);
            }).catch(err => {
                dismiss();
                console.log(err)
            });
    };

    const fetchJobs = () => {

        const searchData = {
            type: 'maintenance_request_belongsto_project_relationship',
            method: 'add'
        };

        authFetch(API_URL + "/maintenance-requests/relation?" + utils.objectToQueryString(searchData), {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
        })
            .then(r => {
                if(r.ok === false && r.status === 401) {
                    logout();
                    history.push('/login');
                    return;
                }
                return r.json()
            })
            .then(response => {
                const message = response?.message;
                const results = response?.results;
                if(!results) {
                    setIserror(true);
                    setMessage(message);
                    console.log('response', response);
                }

                console.log('results', results);
                setJobs(results);
            }).catch(err => {
                console.log(err)
            });
    }

    return (
        <IonPage >
            <IonContent>
                <IonHeader className="home header-toolbar">
                    <IonToolbar>
                        <IonButtons slot="start">

                            <IonButton color="light" onClick={(e) => {
                                e.preventDefault();
                                history.push('/dashboard');
                            }}>
                                <IonIcon icon={arrowBack} ></IonIcon>
                            </IonButton>

                        </IonButtons>

                        <IonButtons className="ion-justify-content-center ion-align-content-center">
                            <h1 className="m-0 ion-justify-content-center text-light head-class" color="light">MAINTENANCE REQUEST</h1>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <div>
                        <IonAlert
                            isOpen={iserror}
                            onDidDismiss={() => setIserror(false)}
                            cssClass="my-custom-class"
                            header={"Error!"}
                            message={message}
                            buttons={["Dismiss"]}
                        />
                    </div>

                    <div className="topHeader"></div>

                    <IonGrid style={{ marginBottom: "90px", marginTop: "40px" }}>
                        <IonRow className="profileActionContainer">
                            <IonCol size="12" className="">
                                <IonCard className="profileHeader">

                                    <IonBreadcrumbs style={{ marginTop: "10px " }}>
                                        <Link to="dashboard"><IonBreadcrumb color="medium">Dashboard</IonBreadcrumb></Link>
                                        <IonBreadcrumb color="primary" active={true}>Maintenance Request</IonBreadcrumb>
                                    </IonBreadcrumbs>

                                    <IonCardContent>
                                        <form onSubmit={onSubmit}>

                                            <div className="form-row">
                                                <div className="form-group col-md-12">
                                                    <label >Choose Project</label>
                                                    <IonSelect className="form-control" placeholder="Select project"
                                                        value={data?.project_id ?? null}
                                                        onIonChange={(e) => onFormChange('project_id', e.detail.value)}>
                                                        <IonSelectOption key={null} value={null}>Choose...</IonSelectOption>
                                                        {jobs && jobs.map((job: any) => {
                                                            return (
                                                                <IonSelectOption key={job.id} value={job.id}>{job.text}</IonSelectOption>
                                                            )
                                                        })}
                                                    </IonSelect>
                                                </div>
                                            </div>

                                            <div className="form-row mb-3">
                                                <div className="form-group file-margin col-md-6">
                                                    <label className="mb-2">Item</label>
                                                    <IonInput type="text" className="form-control" placeholder="Description/Location"
                                                        value={data?.item1_text}
                                                        onIonChange={(e) => onFormChange('item1_text', e.detail.value)}></IonInput>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="mb-2">{'\u00A0'}</label>
                                                    {/* <input type="file" className="custom-file form-control-file" id="exampleFormControlFile1" /> */}
                                                    <input type="file" className="custom-file form-control-file" name="item1_image" accept="image/png, image/gif, image/jpeg" onChange={e => onFileChange('item1_image', e)} />
                                                </div>
                                            </div>
                                            <div className="form-row mb-3">
                                                <div className="form-group file-margin col-md-6">
                                                    <label className="mb-2">Item</label>
                                                    <IonInput type="text" className="form-control" placeholder="Description/Location"
                                                        value={data?.item2_text}
                                                        onIonChange={(e) => onFormChange('item2_text', e.detail.value)}></IonInput>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="mb-2">{'\u00A0'}</label>
                                                    {/* <input type="file" className="custom-file form-control-file" id="exampleFormControlFile1" /> */}
                                                    <input type="file" className="custom-file form-control-file" name="item2_image" accept="image/png, image/gif, image/jpeg" onChange={e => onFileChange('item2_image', e)} />
                                                </div>
                                            </div>
                                            <div className="form-row mb-3">
                                                <div className="form-group file-margin col-md-6">
                                                    <label className="mb-2">Item</label>
                                                    <IonInput type="text" className="form-control" placeholder="Description/Location"
                                                        value={data?.item3_text}
                                                        onIonChange={(e) => onFormChange('item3_text', e.detail.value)}></IonInput>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="mb-2">{'\u00A0'}</label>
                                                    {/* <input type="file" className="custom-file form-control-file" id="exampleFormControlFile1" /> */}
                                                    <input type="file" className="custom-file form-control-file" name="item3_image" accept="image/png, image/gif, image/jpeg" onChange={e => onFileChange('item3_image', e)} />
                                                </div>
                                            </div>
                                            <div className="form-row mb-3">
                                                <div className="form-group file-margin col-md-6">
                                                    <label className="mb-2">Item</label>
                                                    <IonInput type="text" className="form-control" placeholder="Description/Location"
                                                        value={data?.item4_text}
                                                        onIonChange={(e) => onFormChange('item4_text', e.detail.value)}></IonInput>
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="mb-2">{'\u00A0'}</label>
                                                    {/* <input type="file" className="custom-file form-control-file" id="exampleFormControlFile1" /> */}
                                                    <input type="file" className="custom-file form-control-file" name="item4_image" accept="image/png, image/gif, image/jpeg" onChange={e => onFileChange('item4_image', e)} />
                                                </div>
                                            </div>

                                            <p className='ion-text-center sub-text'>Your provider will be notified of your request and will contact you shortly with pricing and schedule.</p>

                                            <button type="submit" className="btn Btnclr w-100">Submit</button>

                                            <IonAlert
                                                isOpen={showAlert}
                                                onDidDismiss={() => setShowAlert(false)}
                                                header="Maintenance Request"
                                                subHeader=""
                                                message="Data Submitted Successfully !"
                                                buttons={['OK']}
                                            />

                                        </form>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <IonToast
                        isOpen={isToastOpen}
                        message={toastMessage}
                        onDidDismiss={() => setIsToastOpen(false)}
                        duration={5000}
                    ></IonToast>
                </IonContent>
            </IonContent >
        </IonPage>

    );
};

export default MaintenanceRequest;
