import {
    IonAlert,
    IonBreadcrumb,
    IonBreadcrumbs,
    IonCard,
    IonCardContent,
    IonCheckbox,
    IonCol,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonLabel,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonToast,
    IonToolbar,
    useIonLoading,
    useIonViewDidEnter
} from '@ionic/react';
import { arrowBack } from "ionicons/icons";
import {
    IonContent,
    IonPage,
    IonButton,
    IonButtons
} from '@ionic/react';
import './index.css';
import { Link, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { API_URL } from '../../config';
import { authFetch } from '../../auth-provider';
import states from '../../config/states';
import areaUnits from '../../config/area-units';
import { logout } from '../../auth-provider';
import * as utils from '../../services/utils';
import BmpActivity from './BmpActivity';
import inspectionTypes from '../../config/inspection-types';
import options from '../../config/options';
import weatherTypes from '../../config/weather-types';
import inspectionItemTypes from '../../config/inspection-item-types';
import tempratureUnits from '../../config/temprature-units';

const PerfromInspection: React.FC = () => {
    const history = useHistory();

    const [showAlert, setShowAlert] = useState(false);
    // eslint-disable-next-line
    const [inspectors, setInspectors] = useState<any[]>([]);
    const [jobs, setJobs] = useState([]);

    const [data, setData] = useState<any>();
    const [formData, setFormData] = useState<any>(new FormData());
    const [fileData, setFileData] = useState<any>();

    const [iserror, setIserror] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState<string>("");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [present, dismiss] = useIonLoading();

    useIonViewDidEnter(() => {
        fetchJobs();
    })

    useEffect(() => {
        if(!data?.project_id) {
            setData(() => {
                return {
                    ...data,
                    ...{
                        inspector_id: null
                    }
                }
            })

            setFormData(() => {
                return utils.jsonToFormData({
                    ...data,
                    ...{
                        inspector_id: null
                    }
                }, formData)
            });
        }
    }, [data?.project_id]);

    const onFormChange = (key:string, value: any) => {
        // console.log('[onFormChange]');
        // console.log('key', key);
        // console.log('value', value);
        // console.log('data', data);

        setData({
            ...data,
            ...{
                [key]: value
            }
        })

        setFormData(() => {
            return utils.jsonToFormData({
                ...data,
                ...{
                    [key]: value
                }
            }, formData)
        });
    };
    const onSubmit = (e: any) => {
        e.preventDefault();

        setIserror(false);
        setMessage("");
        setIsToastOpen(false);
        setToastMessage("");

        present({
            message: 'Submitting...',
        });

        authFetch(API_URL + "/inspections", {
            method: 'POST',
            body: formData,
        })
            .then(r => {
                if(r.ok === false && r.status === 401) {
                    logout();
                    history.push('/login');
                    return;
                }
                return r.json()
            })
            .then(response => {
                dismiss();
                const message = response?.message;
                const data = response?.data;
                if(data) {
                    setIsToastOpen(true);
                    setToastMessage("Inspection added successfully.");
                    history.push("/dashboard");
                    return;
                }
                setIserror(true);
                setMessage(message);
                console.log('response', response);
            }).catch(err => {
                dismiss();
                console.log(err)
            });
    };

    const onBmpChange = (id: any, bmpData: any) => {
        setData({
            ...data,
            ...{
                [id]: bmpData
            }
        })

        setFormData(utils.jsonToFormData({
            ...data,
            ...{
                [id]: bmpData
            }
        }, formData));
    };

    const fetchInspectors = () => {
        if(!data?.project_id) {
            setInspectors([]);
            return;
        }

        const searchData = {
            columns: [{
                name: "project_id",
                search: {
                    value: data?.project_id,
                }
            }],
        };

        authFetch(API_URL + "/inspectors?" + utils.objectToQueryString(searchData), {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
        })
            .then(r => {
                if(r.ok === false && r.status === 401) {
                    logout();
                    history.push('/login');
                    return;
                }
                return r.json()
            })
            .then(response => {
                const message = response?.message;
                const data = response?.data;
                if(!data) {
                    setIserror(true);
                    setMessage(message);
                    console.log('response', response);
                }

                console.log('data', data);
                setInspectors(data);
            }).catch(err => {
                console.log(err)
            });
    }

    const fetchJobs = () => {

        const searchData = {
            type: 'inspection_belongsto_project_relationship',
            method: 'add'
        };

        authFetch(API_URL + "/inspections/relation?" + utils.objectToQueryString(searchData), {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
        })
            .then(r => {
                if(r.ok === false && r.status === 401) {
                    logout();
                    history.push('/login');
                    return;
                }
                return r.json()
            })
            .then(response => {
                const message = response?.message;
                const results = response?.results;
                if(!results) {
                    setIserror(true);
                    setMessage(message);
                    console.log('response', response);
                }

                console.log('results', results);
                setJobs(results);
            }).catch(err => {
                console.log(err)
            });
    }

    useEffect(() => {
        fetchInspectors();
    }, [data?.project_id]);

    useEffect(() => {
        console.log('useEffect 292 inspector_id');
        // fetchInspectors();
        // console.log('inspector_id', data?.inspector_id);
        // console.log('inspectors', inspectors);

        if(!data?.inspector_id) {
            return;
        }
        const selectedInspector = inspectors.find(inspector => inspector.id === data?.inspector_id);

        setData(() => {
            return {
                ...data,
                ...{
                    name: selectedInspector?.name ?? ''
                }
            }
        })

        setFormData(() => {
            return utils.jsonToFormData({
                ...data,
                ...{
                    name: selectedInspector?.name ?? ''
                }
            }, formData)
        });
        
    }, [data?.inspector_id]);
    return (
        <>
            <IonPage>
                <IonContent>
                    <IonHeader className="home header-toolbar">
                        <IonToolbar>
                            <IonButtons slot="start">

                                <IonButton color="light" onClick={(e) => {
                                    e.preventDefault();
                                    history.push('/dashboard');
                                }}>
                                    <IonIcon icon={arrowBack} ></IonIcon>
                                </IonButton>

                            </IonButtons>

                            <IonButtons className="ion-justify-content-center">
                                <IonButtons className="ion-justify-content-center">
                                    <h1 className="text-light m-0 ion-justify-content-center heading-class" color="light">PERFROM NEW INSPECTION</h1>
                                </IonButtons>
                            </IonButtons>


                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <div>
                            <IonAlert
                                isOpen={iserror}
                                onDidDismiss={() => setIserror(false)}
                                cssClass="my-custom-class"
                                header={"Error!"}
                                message={message}
                                buttons={["Dismiss"]}
                            />
                        </div>

                        <div className="topHeader"></div>

                        <IonGrid style={{ marginBottom: "90px", marginTop: "40px" }}>


                            <IonRow className="profileActionContainer">
                                <IonCol size="12" className="">
                                    <IonCard className="profileHeader">

                                        <IonBreadcrumbs style={{ marginTop: "10px " }}>
                                            <Link to="dashboard"><IonBreadcrumb color="medium">Dashboard</IonBreadcrumb></Link>
                                            <IonBreadcrumb color="primary" active={true}>Perfrom New Inspection</IonBreadcrumb>
                                        </IonBreadcrumbs>

                                        <IonCardContent>
                                            <form onSubmit={onSubmit}>
                                                <div className="head">
                                                    <h1 className="h1">PROJECT INFORMATION</h1>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label >Project Name</label>
                                                        <IonSelect className="form-control" placeholder="Select project"
                                                            value={data?.project_id ?? null}
                                                            onIonChange={(e) => onFormChange('project_id', e.detail.value)}>
                                                            <IonSelectOption key={null} value={null}>Choose...</IonSelectOption>
                                                            {jobs && jobs.map((job: any) => {
                                                                return (
                                                                    <IonSelectOption key={job.id} value={job.id}>{job.text}</IonSelectOption>
                                                                )
                                                            })}
                                                        </IonSelect>
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <label >Date</label>
                                                        <IonInput type="date" className="form-control"
                                                            value={data?.inspection_date}
                                                            onIonChange={(e) => onFormChange('inspection_date', e.detail.value)}></IonInput>
                                                    </div>
                                                    <div className="form-group col-md-3">
                                                        <label >Time Of Inspection</label>
                                                        <IonInput type="time" className="form-control"
                                                            value={data?.inspection_time}
                                                            onIonChange={(e) => onFormChange('inspection_time', e.detail.value)}></IonInput>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label >Inspector</label>
                                                        <IonSelect className="form-control" placeholder="Select inspector"
                                                            value={data?.inspector_id ?? null}
                                                            onIonChange={(e) => onFormChange('inspector_id', e.detail.value)}>
                                                            <IonSelectOption key={null} value={null}>Choose...</IonSelectOption>
                                                            {inspectors && inspectors.filter((inspector: any) => {
                                                                return inspector.name
                                                            }).map((inspector: any) => {
                                                                return (
                                                                    <IonSelectOption key={inspector.id} value={inspector.id}>{inspector.name}</IonSelectOption>
                                                                )
                                                            })}
                                                        </IonSelect>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label >Phase Of Construction</label>
                                                        <IonInput type="text" className="form-control" placeholder="Enter text here.."
                                                            value={data?.phase}
                                                            onIonChange={(e) => onFormChange('phase', e.detail.value)}></IonInput>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label >Inspection Type</label>
                                                    <IonSelect className="form-control" placeholder="Select Inspection Type"
                                                        value={data?.inspection_type ?? null}
                                                        onIonChange={(e) => onFormChange('inspection_type', e.detail.value)}>
                                                        <IonSelectOption key={null} value={null}>Choose...</IonSelectOption>
                                                        {inspectionTypes && inspectionTypes.map((inspectionType: any) => {
                                                            return (
                                                                <IonSelectOption key={inspectionType.code} value={inspectionType.code}>{inspectionType.name}</IonSelectOption>
                                                            )
                                                        })}
                                                    </IonSelect>
                                                </div>


                                                <div className="head">
                                                    <h1 className="h1">WEATHER INFORMATION</h1>
                                                </div>
                                                <div className="form-group">
                                                    <label >Has There Been A Storm Event Since The Last Inspection?</label>
                                                    <IonSelect className="form-control"
                                                        value={data?.storm_since_last_inspection ?? null}
                                                        onIonChange={(e) => onFormChange('storm_since_last_inspection', e.detail.value)}>
                                                        <IonSelectOption key={null} value={null}>Choose...</IonSelectOption>
                                                        {options && options.map((option: any) => {
                                                            return (
                                                                <IonSelectOption key={option.code} value={option.code}>{option.name}</IonSelectOption>
                                                            )
                                                        })}
                                                    </IonSelect>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label >If Yes, Date</label>
                                                        <IonInput type="date" className="form-control" placeholder="Enter here.."
                                                            value={data?.last_storm_date}
                                                            onIonChange={(e) => onFormChange('last_storm_date', e.detail.value)}></IonInput>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label >Precipitation Amount (inches)</label>
                                                        <IonInput type="text" className="form-control" placeholder="Enter Amount.."
                                                            value={data?.precipitation_amount}
                                                            onIonChange={(e) => onFormChange('precipitation_amount', e.detail.value)}></IonInput>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label >If Yes, Date</label>
                                                        <IonInput type="date" className="form-control" placeholder="Enter here.."
                                                            value={data?.last_storm_date2}
                                                            onIonChange={(e) => onFormChange('last_storm_date2', e.detail.value)}></IonInput>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label >Precipitation Amount (inches)</label>
                                                        <IonInput type="text" className="form-control" placeholder="Enter Amount.."
                                                            value={data?.precipitation_amount2}
                                                            onIonChange={(e) => onFormChange('precipitation_amount2', e.detail.value)}></IonInput>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label >If Yes, Date</label>
                                                        <IonInput type="date" className="form-control" placeholder="Enter here.."
                                                            value={data?.last_storm_date3}
                                                            onIonChange={(e) => onFormChange('last_storm_date3', e.detail.value)}></IonInput>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label >Precipitation Amount (inches)</label>
                                                        <IonInput type="text" className="form-control" placeholder="Enter Amount.."
                                                            value={data?.precipitation_amount3}
                                                            onIonChange={(e) => onFormChange('precipitation_amount3', e.detail.value)}></IonInput>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label >If Yes, Date</label>
                                                        <IonInput type="date" className="form-control" placeholder="Enter here.."
                                                            value={data?.last_storm_date4}
                                                            onIonChange={(e) => onFormChange('last_storm_date4', e.detail.value)}></IonInput>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label >Precipitation Amount (inches)</label>
                                                        <IonInput type="text" className="form-control" placeholder="Enter Amount.."
                                                            value={data?.precipitation_amount4}
                                                            onIonChange={(e) => onFormChange('precipitation_amount4', e.detail.value)}></IonInput>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label >Current Weather</label>
                                                    <IonSelect className="form-control"
                                                        value={data?.current_weather ?? null}
                                                        onIonChange={(e) => onFormChange('current_weather', e.detail.value)}>
                                                        <IonSelectOption key={null} value={null}>Choose...</IonSelectOption>
                                                        {weatherTypes && weatherTypes.map((weatherType: any) => {
                                                            return (
                                                                <IonSelectOption key={weatherType.code} value={weatherType.code}>{weatherType.name}</IonSelectOption>
                                                            )
                                                        })}
                                                    </IonSelect>
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label >Current Temperature</label>
                                                        <div className="input-group">
                                                            <IonInput type="text" className="form-control" placeholder="Enter Temperature"
                                                                value={data?.current_temprature}
                                                                onIonChange={(e) => onFormChange('current_temprature', e.detail.value)}></IonInput>
                                                            <div className="input-group-append">
                                                                <IonSelect
                                                                    className=" btn btn-outline-primary form-control"
                                                                    value={data?.current_temprature_unit ?? 'F'}
                                                                    onIonChange={(e) => onFormChange('current_temprature_unit', e.detail.value)}
                                                                >
                                                                    {tempratureUnits && tempratureUnits.map((tempratureUnit: any) => {
                                                                        return (
                                                                            <IonSelectOption key={tempratureUnit.code} value={tempratureUnit.code}>{tempratureUnit.name}</IonSelectOption>
                                                                        )
                                                                    })}
                                                                </IonSelect>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label >Past Week Trend</label>
                                                        <IonInput type="text" className="form-control" placeholder="Enter here.."
                                                            value={data?.past_week_trend}
                                                            onIonChange={(e) => onFormChange('past_week_trend', e.detail.value)}></IonInput>
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-6">
                                                        <label >Has There Been A Discharge Since The Last Inspection?</label>
                                                        <IonSelect className="form-control"
                                                            value={data?.discharge_since_last_inspection ?? null}
                                                            onIonChange={(e) => onFormChange('discharge_since_last_inspection', e.detail.value)}>
                                                            <IonSelectOption key={null} value={null}>Choose...</IonSelectOption>
                                                            {options && options.map((option: any) => {
                                                                return (
                                                                    <IonSelectOption key={option.code} value={option.code}>{option.name}</IonSelectOption>
                                                                )
                                                            })}
                                                        </IonSelect>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label >Is There A Discharge At The Time Of This Inspection?</label>
                                                        <IonSelect className="form-control"
                                                            value={data?.discharge_during_this_inspection ?? null}
                                                            onIonChange={(e) => onFormChange('discharge_during_this_inspection', e.detail.value)}>
                                                            <IonSelectOption key={null} value={null}>Choose...</IonSelectOption>
                                                            {options && options.map((option: any) => {
                                                                return (
                                                                    <IonSelectOption key={option.code} value={option.code}>{option.name}</IonSelectOption>
                                                                )
                                                            })}
                                                        </IonSelect>
                                                    </div>
                                                </div>



                                                <div className="head">
                                                    <h1 className="h1">PREVIOUS ACTION PLAN ITEMS</h1>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-4">
                                                        <h2 className="h2 mt-2" style={{fontWeight:"600"}}>BMP / ACTIVITY:  xxxx</h2>
                                                        <label >CORRECTED?</label>
                                                        <IonSelect className="form-control"
                                                            value={data?.papi_activity_1_corrected ?? null}
                                                            onIonChange={(e) => onFormChange('papi_activity_1_corrected', e.detail.value)}>
                                                            <IonSelectOption key={null} value={null}>Choose...</IonSelectOption>
                                                            {options && options.map((option: any) => {
                                                                return (
                                                                    <IonSelectOption key={option.code} value={option.code}>{option.name}</IonSelectOption>
                                                                )
                                                            })}
                                                        </IonSelect>
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <h2 className="h2 mt-2" style={{fontWeight:"600"}}>BMP / ACTIVITY:  xxxx</h2>
                                                        <label >CORRECTED?</label>
                                                        <IonSelect className="form-control"
                                                            value={data?.papi_activity_2_corrected ?? null}
                                                            onIonChange={(e) => onFormChange('papi_activity_2_corrected', e.detail.value)}>
                                                            <IonSelectOption key={null} value={null}>Choose...</IonSelectOption>
                                                            {options && options.map((option: any) => {
                                                                return (
                                                                    <IonSelectOption key={option.code} value={option.code}>{option.name}</IonSelectOption>
                                                                )
                                                            })}
                                                        </IonSelect>
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <h2 className="h2 mt-2" style={{fontWeight:"600"}}>BMP / ACTIVITY:  xxxx</h2>
                                                        <label >CORRECTED?</label>
                                                        <IonSelect className="form-control"
                                                            value={data?.papi_activity_3_corrected ?? null}
                                                            onIonChange={(e) => onFormChange('papi_activity_3_corrected', e.detail.value)}>
                                                            <IonSelectOption key={null} value={null}>Choose...</IonSelectOption>
                                                            {options && options.map((option: any) => {
                                                                return (
                                                                    <IonSelectOption key={option.code} value={option.code}>{option.name}</IonSelectOption>
                                                                )
                                                            })}
                                                        </IonSelect>
                                                    </div>
                                                </div>


                                                <div className="head">
                                                    <h1 className="h1">INSPECTION ITEMS</h1>
                                                </div>

                                                {inspectionItemTypes && inspectionItemTypes.map((inspectionItemType: any) => {
                                                    return (
                                                        <BmpActivity
                                                            bmpId={inspectionItemType.code}
                                                            bmpTitle={inspectionItemType.name}
                                                            onBmpChange={onBmpChange}
                                                        />
                                                    )
                                                })}

                                                <div className="head">
                                                    <h1 className="h1">CURRENT ACTION PLAN ITEMS</h1>
                                                </div>
                                                <div className="form-row">
                                                    <div className="form-group col-md-4">
                                                        <h2 className="h2 mt-2" style={{fontWeight:"600"}}>BMP / ACTIVITY:  xxxx</h2>
                                                        <label >CORRECTED?</label>
                                                        <select className="form-control">
                                                            <option selected>Choose...</option>
                                                            <option value="YES">Yes</option>
                                                            <option value="NO">No</option>
                                                            <option value="N/A">N/A</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <h2 className="h2 mt-2" style={{fontWeight:"600"}}> BMP / ACTIVITY:  xxxx</h2>
                                                        <label >CORRECTED?</label>
                                                        <select className="form-control">
                                                            <option selected>Choose...</option>
                                                            <option value="YES">Yes</option>
                                                            <option value="NO">No</option>
                                                            <option value="N/A">N/A</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group col-md-4">
                                                        <h2 className="h2 mt-2" style={{fontWeight:"600"}}>BMP / ACTIVITY:  xxxx</h2>
                                                        <label >CORRECTED?</label>
                                                        <select className="form-control">
                                                            <option selected>Choose...</option>
                                                            <option value="YES">Yes</option>
                                                            <option value="NO">No</option>
                                                            <option value="N/A">N/A</option>
                                                        </select>
                                                    </div>
                                                </div>


                                                <h2 className="h2 my-2 text-center">CERTIFICATION STATEMENT</h2>
                                                <p className='ion-text-center sub-text'>
                                                    “I certify under penalty of law that this document and all attachments were prepared under my direction or supervision in accordance with a system designed to assure that qualified personnel properly gathered and evaluated the information submitted. Based on my inquiry of the person or persons who manage the system, or those persons directly responsible for gathering the information, the information submitted is, to the best of my knowledge and belief, true, accurate, and complete. I am aware that there are significant penalties for submitting false information, including the possibility of fine and imprisonment for knowing violations.”
                                                </p>

                                                <div className="form-row mt-3">
                                                    <div className="form-group col-md-6">
                                                        <label >NAME</label>
                                                        <IonInput type="text" className="form-control"
                                                            value={data?.name}
                                                            onIonChange={(e) => onFormChange('name', e.detail.value)}></IonInput>
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <label >SIGNATURE</label>
                                                        <IonInput type="text" className="form-control"
                                                            value={data?.signature}
                                                            onIonChange={(e) => onFormChange('signature', e.detail.value)}></IonInput>
                                                    </div>
                                                </div>

                                                <div className="form-group">
                                                    <label >COMMENTS</label>
                                                    <IonTextarea
                                                        className="form-control"
                                                        placeholder="Comments"
                                                        value={data?.comments}
                                                        onIonChange={(e) => onFormChange('comments', e.detail.value)}
                                                    ></IonTextarea>
                                                </div>



                                                <button type="submit" className="btn Btnclr w-100">Submit</button>

                                                <IonAlert
                                                    isOpen={showAlert}
                                                    onDidDismiss={() => setShowAlert(false)}
                                                    header="Perfrom New Inspection"
                                                    subHeader=""
                                                    message="Data Submitted Successfully !"
                                                    buttons={['OK']}
                                                />
                                            </form>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                        <IonToast
                            isOpen={isToastOpen}
                            message={toastMessage}
                            onDidDismiss={() => setIsToastOpen(false)}
                            duration={5000}
                        ></IonToast>
                    </IonContent>
                </IonContent >
            </IonPage>
        </>
    );
};

export default PerfromInspection;
