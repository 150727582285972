const tempratureUnits = [
    {
        "name": "˚ F",
        "code": "F"
    },
    {
        "name": "˚ C",
        "code": "C"
    }
]

export default tempratureUnits;