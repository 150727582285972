import {
    IonBreadcrumb,
    IonBreadcrumbs,
    IonCard,
    IonCardContent,
    IonCol,
    IonGrid,
    IonHeader,
    IonIcon,
    // IonInfiniteScroll,
    // IonInfiniteScrollContent,
    IonRow,
    IonToolbar,
    useIonViewWillEnter
} from '@ionic/react';
import { arrowBack } from "ionicons/icons";
import {
    IonContent,
    IonPage,
    IonButton,
    IonButtons
} from '@ionic/react';
import './index.css';
import { Link, useHistory } from 'react-router-dom';
import useFetchList from '../../useFetchList';

const VewjJobs: React.FC = () => {
    const history = useHistory();

    // const [showAlert, setShowAlert] = useState(false);

    // console.log('called');
    // const { data, meta, loading, refetch, loadMore } = useFetchList("/jobs", {}, {});
    const { data, loading, error, refetch } = useFetchList("/jobs", {
        method: 'GET',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    }, {});
    console.log('data', data);
    console.log('error', error);

    // const currentPage = meta?.current_page || 1;
    // const lastPage = meta?.last_page || 1;

    // const canLoadMore = currentPage < lastPage;

    useIonViewWillEnter(() => {
        refetch();
      });

    return (

        <IonPage>
            <IonContent>

                <IonHeader className="home header-toolbar">
                    <IonToolbar>
                        <IonButtons slot="start">

                            <IonButton color="light" onClick={(e) => {
                                e.preventDefault();
                                history.push('/dashboard');
                            }}>
                                <IonIcon icon={arrowBack} ></IonIcon>
                            </IonButton>

                        </IonButtons>

                        <IonButtons className="ion-justify-content-center">
                            <IonButton color="light">
                                <h1 className="" color="light">JOBS DETAILS</h1>
                            </IonButton>
                        </IonButtons>

                    </IonToolbar>
                </IonHeader>
                <IonContent>

                    <div className="topHeader"></div>

                    <IonGrid style={{ marginTop: "40px " }}>
                        <IonRow className="profileActionContainer">
                            <IonCol size="12" className="">
                                <IonCard className="profileHeader">

                                    <IonBreadcrumbs style={{ marginTop: "10px " }}>
                                        <Link to="dashboard"><IonBreadcrumb color="medium">Dashboard</IonBreadcrumb></Link>
                                        <IonBreadcrumb color="primary" active={true}>Jobs Details</IonBreadcrumb>
                                    </IonBreadcrumbs>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                    {
                    !data && (
                        <IonGrid style={{ marginTop: "40px " }}>
                            <IonRow className="profileActionContainer">
                                <IonCol size="12" className="">
                                    <IonCard className="profileHeader">
                                        <IonCardContent>
                                            <form>
                                                <div className="flex-between">
                                                    <div className="form-group">
                                                        {
                                                        loading && (
                                                            <p>Loading...</p>
                                                        )}
                                                        {
                                                        !loading && (
                                                            <p>{error ?? 'No results'}.</p>
                                                        )}
                                                    </div>
                                                </div>
                                            </form>
                                        </IonCardContent>
                                    </IonCard>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    )}

                    {data && data.map((item: any) => {
                        return (
                            <IonGrid>
                                    <IonRow className="profileActionContainer">
                                        <IonCol size="12" className="">
                                            <IonCard className="profileHeader">

                                                <IonCardContent>
                                                    <form>
                                                        <div className="flex-between">
                                                            <div className="form-group">
                                                                <label className="mb-2">Project Name</label>
                                                                <p>{item?.project_name}</p>
                                                            </div>
                                                            <div className="form-group">
                                                                <label className="mb-2">Project Type</label>
                                                                <p>Building Construction </p>
                                                            </div>
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="inputAddress">Street Address</label>
                                                            <p>{item?.location} {item?.city} {item?._state ?? null}</p>
                                                        </div>

                                                        <div className="flex-between">
                                                            <div className="form-group">
                                                                <label >Parcel Number</label>
                                                                <p> {item?.parcel_number ?? 'N-456789767'}</p>
                                                            </div>
                                                            <div className="form-group">
                                                                <label >Total Site Area</label>
                                                                <p>{item?.total_site_area ?? '1.2'} {item?.total_site_area_unit ?? 'Acres'}</p>
                                                            </div>
                                                        </div>

                                                        <div className="flex-between">
                                                            <div className="form-group">
                                                                <label >Disturbed Area</label>
                                                                <p>{item?.disturbed_area ?? '1.2'} {item?.disturbed_area_unit ?? 'Acres'}</p>
                                                            </div>
                                                            <div className="form-group">
                                                                <label >Permit Number</label>
                                                                <p>{item?.permit_number ?? 'P-095689767'}</p>
                                                            </div>
                                                        </div>

                                                    </form>


                                                </IonCardContent>
                                            </IonCard>
                                        </IonCol>
                                    </IonRow>

                                </IonGrid>
                        )
                    })}

                    {/* { canLoadMore && (
                    <IonInfiniteScroll
                        onIonInfinite={(ev) => loadMore(() => ev.target.complete())}
                    >
                        <IonInfiniteScrollContent></IonInfiniteScrollContent>
                    </IonInfiniteScroll>
                    )} */}
                </IonContent>

            </IonContent >
        </IonPage >

    );
};

export default VewjJobs;
