import {
    IonAlert,
    IonBreadcrumb,
    IonBreadcrumbs,
    IonCard,
    IonCardContent,
    IonCol,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonToast,
    IonToolbar,
    useIonLoading
} from '@ionic/react';
import { arrowBack } from "ionicons/icons";
import {
    IonContent,
    IonPage,
    IonButton,
    IonButtons
} from '@ionic/react';
import './index.css';
import { Link, useHistory } from 'react-router-dom';
import { useState } from 'react';
import { API_URL } from '../../config';
import { authFetch } from '../../auth-provider';
import states from '../../config/states';
import { logout } from '../../auth-provider';

const ServiceRequest: React.FC = () => {
    const history = useHistory();

    const [showAlert, setShowAlert] = useState(false);

    const [formData, setFormData] = useState<any>();

    const [iserror, setIserror] = useState<boolean>(false);
    const [message, setMessage] = useState<string>("");
    const [isToastOpen, setIsToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState<string>("");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [present, dismiss] = useIonLoading();

    const onFormChange = (key:string, value: any) => {
        // console.log('[onFormChange]');
        // console.log('key', key);
        // console.log('value', value);
        // console.log('formData', formData);

        setFormData({
            ...formData,
            ...{
                [key]: value
            }
        })
    };
    const onSubmit = (e: any) => {
        e.preventDefault();

        setIserror(false);
        setMessage("");
        setIsToastOpen(false);
        setToastMessage("");

        present({
            message: 'Submitting...',
        });

        authFetch(API_URL + "/service-requests", {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
        })
            .then(r => {
                if(r.ok === false && r.status === 401) {
                    logout();
                    history.push('/login');
                    return;
                }
                return r.json()
            })
            .then(response => {
                dismiss();
                const message = response?.message;
                const data = response?.data;
                if(data) {
                    setIsToastOpen(true);
                    setToastMessage("Service Request added successfully.");
                    history.push("/dashboard");
                    return;
                }
                setIserror(true);
                setMessage(message);
                console.log('response', response);
            }).catch(err => {
                dismiss();
                console.log(err)
            });
    };

    return (


        <IonPage >
            <IonContent>
                <IonHeader className="home header-toolbar">
                    <IonToolbar>
                        <IonButtons slot="start">

                            <IonButton color="light" onClick={(e) => {
                                e.preventDefault();
                                history.push('/dashboard');
                            }}>
                                <IonIcon icon={arrowBack} ></IonIcon>
                            </IonButton>

                        </IonButtons>

                        <IonButtons className="ion-justify-content-center">
                            <IonButtons className="ion-justify-content-center">
                                <h1 className="text-center m-0 text-light headng-class" color="light">NEW SERVICE REQUEST / QUOTE</h1>
                            </IonButtons>
                        </IonButtons>

                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <div>
                        <IonAlert
                            isOpen={iserror}
                            onDidDismiss={() => setIserror(false)}
                            cssClass="my-custom-class"
                            header={"Error!"}
                            message={message}
                            buttons={["Dismiss"]}
                        />
                    </div>

                    <div className="topHeader"></div>

                    <IonGrid style={{ marginBottom: "90px", marginTop: "40px " }}>



                        <IonRow className="profileActionContainer">
                            <IonCol size="12" className="">
                                <IonCard className="profileHeader">

                                    <IonBreadcrumbs style={{ marginTop: "10px" }}>
                                        <Link to="dashboard"><IonBreadcrumb color="medium">Dashboard</IonBreadcrumb></Link>
                                        <IonBreadcrumb color="primary" active={true}>New Service Request</IonBreadcrumb>
                                    </IonBreadcrumbs>

                                    <IonCardContent>
                                        <form onSubmit={onSubmit}>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label className="mb-2">Project Name</label>
                                                    <IonInput
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter New Project Name"
                                                        value={formData?.project_name}
                                                        onIonChange={(e) => onFormChange('project_name', e.detail.value)}
                                                    ></IonInput>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label className="mb-2">Location</label>
                                                    <IonInput
                                                        type="text"
                                                        className="form-control"
                                                        id="inputAddress"
                                                        placeholder="e.g. 1234 Main St..."
                                                        value={formData?.location}
                                                        onIonChange={(e) => onFormChange('location', e.detail.value)}
                                                    ></IonInput>
                                                </div>
                                            </div>

                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label >City</label>
                                                    <IonInput
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter City Name"
                                                        value={formData?.city}
                                                        onIonChange={(e) => onFormChange('city', e.detail.value)}
                                                    ></IonInput>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label >State</label>
                                                    <IonSelect
                                                        className="form-control"
                                                        placeholder="Select state"
                                                        value={formData?._state ?? null}
                                                        onIonChange={(e) => onFormChange('state', e.detail.value)}
                                                    >
                                                        <IonSelectOption key={null} value={null}>Choose...</IonSelectOption>
                                                        {states && states.map((state: any) => {
                                                            return (
                                                                <IonSelectOption key={state.code} value={state.code}>{state.name}</IonSelectOption>
                                                            )
                                                        })}
                                                    </IonSelect>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label >Contractor Name</label>
                                                    <IonInput
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Name"
                                                        value={formData?.name}
                                                        onIonChange={(e) => onFormChange('name', e.detail.value)}
                                                    ></IonInput>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label >Contractor Email</label>
                                                    <IonInput
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Email"
                                                        value={formData?.email}
                                                        onIonChange={(e) => onFormChange('email', e.detail.value)}
                                                    ></IonInput>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label >MESSAGE</label>
                                                <IonTextarea
                                                    className="form-control"
                                                    placeholder="Enter Message"
                                                    value={formData?.message}
                                                    onIonChange={(e) => onFormChange('message', e.detail.value)}
                                                ></IonTextarea>
                                            </div>
                                            <p className='ion-text-center sub-text'>Your provider will be notified of your request and will contact you shortly with pricing and schedule.</p>

                                            <button type="submit" className="btn Btnclr w-100">Submit</button>


                                            <IonAlert
                                                isOpen={showAlert}
                                                onDidDismiss={() => setShowAlert(false)}
                                                header="New Service Request"
                                                subHeader=""
                                                message="Data Submitted Successfully !"
                                                buttons={['OK']}
                                            />

                                        </form>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <IonToast
                        isOpen={isToastOpen}
                        message={toastMessage}
                        onDidDismiss={() => setIsToastOpen(false)}
                        duration={5000}
                    ></IonToast>
                </IonContent>
            </IonContent >
        </IonPage>

    );
};

export default ServiceRequest;
